import Vue from 'vue'
import VueRouter from 'vue-router'
import enter from '@/views/enter'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'enter',
    component: enter
  },
  {
    path: '/liveRoomTeacher/:roomID/:userID/:userName/:fps',
    name: 'liveRoomTeacher',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "liveRoomTeacher" */ '../views/oneToOne/liveRoomTeacher.vue')
  },
  {
    path: '/liveRoomStudent/:roomID/:userID/:userName/:fps',
    name: 'liveRoomStudent',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "liveRoomStudent" */ '../views/oneToOne/liveRoomStudent.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
