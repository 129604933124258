<template>
  <div class="enter">
    <div class="enter_box">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        size="small"
      >
        <el-form-item label="用户角色" prop="userRole">
          <el-radio-group v-model="ruleForm.userRole">
            <el-radio :label="1">学生</el-radio>
            <el-radio :label="2">老师</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="视频帧率" prop="fps">
          <el-select v-model="ruleForm.fps" placeholder="请选择">
            <el-option
              v-for="item in videoFpsList"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="房间ID" prop="roomID">
          <el-input
            v-model="ruleForm.roomID"
            autocomplete="new_roomID"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户ID" prop="userID">
          <el-input
            v-model="ruleForm.userID"
            autocomplete="new_userID"
          ></el-input>
        </el-form-item>
        <el-form-item label="用户名" prop="userName">
          <el-input v-model="ruleForm.userName"></el-input>
        </el-form-item>
      </el-form>
      <div class="form_opt">
        <el-button type="primary" @click="submitForm('ruleForm')" size="small"
            >进入教室</el-button
          >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        roomID: "123456",
        userID: '',
        userName: '',
        userRole: 1,   //1学生   2老师
        fps: 20,
      },
      videoFpsList: [
        {
          name: '9 fps',
          id: 9
        },
        {
          name: '12 fps',
          id: 12
        },
        {
          name: '15 fps',
          id: 15
        },
        {
          name: '18 fps',
          id: 18
        },
        {
          name: '20 fps',
          id: 20
        },
        {
          name: '24 fps',
          id: 24
        },
        {
          name: '30 fps',
          id: 30
        },
      ],
      rules: {
        roomID: [{ required: true, message: '房间ID不能为空', trigger: 'change' }],
        userID: [{ required: true, message: '用户ID不能为空', trigger: 'change' }],
        userName: [{ required: true, message: '用户名不能为空', trigger: 'change' }],
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$router.push({
            name: this.ruleForm.userRole == 2 ? "liveRoomTeacher" : 'liveRoomStudent',
            params: {
              roomID: this.ruleForm.roomID,
              userID: this.ruleForm.userID,
              userName: this.ruleForm.userName,
              fps: this.ruleForm.fps
            },
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
  },
  created() {},
  mounted() {

  },
};
</script>
<style scoped lang="scss">
/* @import url(); 引入css类 */
.enter {
  width: 100vw;
  height: 100vh;
  position: relative;
  .enter_box {
    width: 400px;
    height: 360px;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
  }
  .form_opt{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>